import { inject, Injectable, type Provider } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LocalStorageService } from '@cosmos/data-access-storage';

import { FeatureFlagsService } from '../providers';
import {
  FEATURE_FLAGS_FROM_QUERY_DATA,
  FEATURE_FLAGS_OPTIONS,
} from '../tokens';
import type { ExplicitFeatureFlagsMap, FeatureFlagsOptions } from '../types';

@Injectable({ providedIn: 'root' })
export class ExplicitFeatureFlagsService<
  TFeatureFlags extends string = string,
> extends FeatureFlagsService<TFeatureFlags> {
  static featureFlagOverridesStorageKey = 'featureFlagOverrides';
  /** development or release */
  static featureFlagGroupStorageKey = 'featureFlagsSet';
  private readonly _options = inject<FeatureFlagsOptions>(
    FEATURE_FLAGS_OPTIONS,
    { optional: true }
  );
  private readonly _queryData = inject(FEATURE_FLAGS_FROM_QUERY_DATA, {
    optional: true,
  });
  private readonly _localStorageService = inject(LocalStorageService);

  private _flags$ = new BehaviorSubject<
    Partial<Record<TFeatureFlags, boolean>>
  >({});

  static provideWithConfig<TFeatureFlags extends string>(
    flags: ExplicitFeatureFlagsMap<TFeatureFlags>
  ): Provider {
    return {
      provide: ExplicitFeatureFlagsService,
      useFactory: () => {
        const service = new ExplicitFeatureFlagsService<TFeatureFlags>();
        service.setFlags(flags);
        return service;
      },
    };
  }

  selectedFeatureFlagName = this._getSelectedFeatureFlagName();

  readonly flags$ = this._flags$.asObservable();

  setFlags(flags: Partial<Record<TFeatureFlags, boolean>>): void {
    this._flags$.next(Object.assign({}, this._flags$.value, flags));
  }

  isEnabled(flagIdentifier: TFeatureFlags): boolean {
    const negateFlag = flagIdentifier[0] === '!';

    if (negateFlag) {
      return (
        !this._flags$.value[flagIdentifier.slice(1) as TFeatureFlags] || false
      );
    }

    return this._flags$.value[flagIdentifier] || false;
  }

  isEnabled$(flagIdentifier: TFeatureFlags): Observable<boolean> {
    return this.flags$.pipe(
      map(() => this.isEnabled(flagIdentifier)),
      distinctUntilChanged()
    );
  }

  private _getSelectedFeatureFlagName(): string {
    // this.options === { "development": {...}, "release": {...} }
    // Object.keys(this.options)?.[0] is resolved as "development"
    const defaultFeatureFlag = this._options
      ? Object.keys(this._options)?.[0]
      : '';
    const featureFlag: string | null =
      this._queryData?.queryFlag ||
      this._localStorageService.getItem<string>(
        ExplicitFeatureFlagsService.featureFlagGroupStorageKey
      );

    if (featureFlag && !this._options?.[featureFlag]) {
      return defaultFeatureFlag;
    }

    return featureFlag || defaultFeatureFlag;
  }
}
